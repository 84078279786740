<template>
  <router-view class="view" />
</template>
<script>
import { RouterView } from 'vue-router';
import { get } from '@/utils/http';
import { Storage } from "wl-core";
import { onMounted } from 'vue';
export default {
  components: {
    RouterView
  },
  setup () {
    onMounted(async () => {
      document.title = "动态评价系统";
      let envUrl = (window.location.hostname.indexOf("com") != -1) ? "https://configapi.dyxdezx.com:/env" : window.location.protocol + "//" + window.location.hostname + ":9000/Env"
      let data = (await get(envUrl)).data;
      Storage.set('config', data);
    })
  }
}
</script>

<style lang="less">
</style>
