import { message } from 'ant-design-vue';
const success = (content, durantion = 3, onClose = () => { }) => {
  message.success(content, durantion, onClose)
}
const loading = (content, durantion = 3, onClose = () => { }) => {
  message.loading(content, durantion, onClose)
}
const error = (content, durantion = 3, onClose = () => { }) => {
  message.error(content, durantion, onClose)
}
const info = (content, durantion = 3, onClose = () => { }) => {
  message.info(content, durantion, onClose)
}
const warning = (content, durantion = 3, onClose = () => { }) => {
  message.warning(content, durantion, onClose)
}
const warn = (content, durantion = 3, onClose = () => { }) => {
  message.warn(content, durantion, onClose)
}
export {
  success,
  loading,
  error,
  info,
  warning,
  warn
}