<template>
    <div class="the_content">
        <div class="top">
            <div class="title">{{data.name}}</div>
            <div class="mtop"></div>
        </div>
        <div class="content">
            <div class="warp" v-for="item in data.targetGroups" :key="item.sid">
                <div class="title">{{item.name}}</div>
                <div class="list">
                    <div
                        class="item"
                        @click="next(children,item.sid)"
                        v-for="children in item.members"
                        :key="children.rid"
                    >
                        <span>{{children.name}} ({{children.record.operationCount}}/{{children.record.count}})</span>
                        <div class="more">
                            查看更多
                            <RightOutlined />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="back" @click="back()">
            <RollbackOutlined />
        </div>
    </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { RightOutlined, RollbackOutlined } from '@ant-design/icons-vue';
import { ref, onMounted } from 'vue';
import { get, config } from '@/utils/http';
import { Storage } from "wl-core";
export default {
    components: {
        RightOutlined, RollbackOutlined
    },
    setup() {
        let router = useRouter();
        let data = ref({});
        const next = (obj, id) => {
            obj.groupsId = id;
            Storage.set('detail', obj)
            router.push({ name: 'detail' });
        }

        let res = Storage.get('data');

        // 模拟生成数据去重
        res.targetGroups.forEach(x=>{
            x.members.forEach(e=>{
               res.tables.forEach(s=>  {
                   if(!res.targetMemberCommits.find(c=>c.table==s.rid&&e.rid==c.member)){
                       res.targetMemberCommits.push({committed: false,group: x.sid,member: e.rid,table: s.rid}
                    )}
               } )
            })
        })

        // 教师和评价表归类
        let getTeachersTable = (x) => {
            let obj = {
                count: 0,
                operationCount: 0,
                operateList: [],
                onOperateList: []
            };
            res.targetMemberCommits.forEach(c => {
                if (c.member == x) {
                    obj.count++
                    if (c.committed) {
                        obj.operationCount++;
                        obj.operateList.push(c)
                    } else {
                        obj.onOperateList.push(c)
                    }
                }
            })
            return obj
        }

        const init = (res) => {
            res.targetGroups.forEach(x => {
                x.members.forEach(x => {
                    x.record = getTeachersTable(x.rid)
                })
            })
            data.value = {
                name: res.name,
                targetGroups: res.targetGroups
            }
        }

        init(res);

        const back = () => {
            router.push({ name: 'query', params: { id: localStorage.getItem('id') } });
        }

        onMounted(async () => {
            setTimeout(async () => {
                let res = (await get(`${config.evalution}/Project/ForH5/GetProjectHandle/${localStorage.getItem('id')}/${localStorage.getItem('idno')}`)).data;
                init(res);
                Storage.set('data', res);
            }, 1000)
        })

        return {
            next,
            data,
            back
        }
    }
}
</script>

<style lang="less" scoped>
.the_content {
    width: 814px;
    padding: 10px 0px 66px;
    padding-top: 20px;
    background: #fff;
    position: relative;
    .top {
        .title {
            padding: 0 40px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
        }
        .mtop {
            width: 100%;
            height: 3px;
            background: #1890ff;
            margin-top: 20px;
        }
    }
    .content {
        .warp {
            margin: 15px;
            .title {
                font-size: 16px;
                color: #999;
                font-weight: 500;
            }
            .list {
                display: flex;
                flex-wrap: wrap;
                .item {
                    width: 372px;
                    padding: 10px 20px;
                    height: 40px;
                    line-height: 20px;
                    border: 1px solid #1890ff;
                    border-radius: 2px;
                    margin: 10px;
                    color: #000;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 16px;
                    cursor: pointer;
                    &:hover {
                        background: #f5f7f9;
                        border-color: #f5f7f9;
                        .more {
                            color: #1890ff;
                        }
                    }
                    .more {
                        color: #ccd0d7;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .back {
        position: absolute;
        height: 60px;
        width: 60px;
        background: #fff;
        color: #1890ff;
        border: 1px solid #1890ff;
        font-size: 24px;
        border-radius: 50%;
        right: 20px;
        bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            background: #1890ff;
            color: #fff;
        }
    }
}
@media only screen and (max-width: 600px) {
    .the_content {
        width: 100%;
        height: 100%;
        overflow: auto;
        .back {
            position: fixed;
        }
    }
}
</style>
