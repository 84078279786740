<template>
    <div class="the_content">
        <div class="top">
            <div class="title">{{data.title}}</div>
            <div class="mtop"></div>
        </div>
        <div class="content">
            <div class="warp">
                <a-form>
                    <a-form-item label="被评价人" labelAlign="left">
                        <span style="font-size: 20px;font-weight: 600;">{{data.name}}</span>
                    </a-form-item>
                    <div class="title" v-if="data.record.onOperateList.length">待评价表</div>
                    <div class="list" v-if="data.record.onOperateList.length">
                        <div
                            class="item"
                            @click="next(data.groupsId,item.member,item.table)"
                            v-for="item in data.record.onOperateList"
                            :key="item.table"
                        >
                            <span class="span">{{(tables.find(x=>x.rid==item.table)).name}}</span>
                            <div class="more">
                                查看更多
                                <RightOutlined />
                            </div>
                        </div>
                    </div>
                    <div
                        class="title"
                        v-if="data.record.operateList.length"
                        style="margin-top:20px;color:#19be6b"
                    >已评价表</div>
                    <div class="list">
                        <div
                            class="item"
                            @click="getDeailTable(data.groupsId,item.member,item.table)"
                            v-for="item in data.record.operateList"
                            :key="item.table"
                        >
                            <span class="span">{{(tables.find(x=>x.rid==item.table)).name}}</span>
                            <div class="more">
                                查看更多
                                <RightOutlined />
                            </div>
                        </div>
                    </div>
                </a-form>
            </div>
        </div>
        <div class="back" @click="back()">
            <RollbackOutlined />
        </div>
    </div>
</template>
<script>
import { get, config } from '@/utils/http';
import { useRouter } from 'vue-router';
import { RightOutlined, RollbackOutlined } from '@ant-design/icons-vue';
import { Storage } from "wl-core";
import { ref } from 'vue';

export default {
    components: {
        RightOutlined, RollbackOutlined
    },
    setup() {
        let router = useRouter();
        let data = ref({});
        let tables = ref([]);
        const next = (groupId, member, tableId) => {
            Storage.set("ids", { processid: Storage.get("data").process, groupid: groupId, member: member, table: tableId })
            router.push({ name: 'evaluate' });
        }

        const getDeailTable = async (groupId, member, tableId) => {
            let ids = { processid: Storage.get("data").process, groupid: groupId, member: member, table: tableId };
            Storage.set("ids", ids)
            let res = (await get(`${config.evalution}/Project/ForH5/${ids.processid}/ProjectEvalutionToMember/${ids.groupid}/${ids.member}/${ids.table}`)).data;
            Storage.set('deailtable', res)
            router.push({ name: 'deailtable' });
        }
        const back = () => {
            router.push({ name: 'selecteachers' });
        }
        data.value = Storage.get("detail");
        data.value.title = Storage.get("data").name;
        tables.value = Storage.get("data").tables;
        return {
            data,
            tables,
            next,
            back,
            getDeailTable
        }
    }
}
</script>

<style lang="less" scoped>
.the_content {
    width: 814px;
    padding: 10px 0px 66px;
    padding-top: 20px;
    background: #fff;
    position: relative;
    .top {
        .title {
            padding: 0 40px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
        }
        .mtop {
            width: 100%;
            height: 3px;
            background: #1890ff;
            margin-top: 20px;
        }
    }
    .content {
        .warp {
            margin: 15px;
            .title {
                font-size: 16px;
                color: #999;
                font-weight: 500;
            }
            .list {
                display: flex;
                flex-wrap: wrap;
                .item {
                    width: 372px;
                    padding: 10px 20px;
                    // height: 90px;
                    // line-height: 20px;
                    border: 1px solid #1890ff;
                    border-radius: 2px;
                    margin: 10px;
                    color: #000;
                    // display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 16px;
                    cursor: pointer;
                    &:hover {
                        background: #f5f7f9;
                        border-color: #f5f7f9;
                        .more {
                            color: #1890ff;
                        }
                    }
                    .span {
                        margin-top: 10px;
                        width: 180px;
                    }
                    .more {
                        color: #ccd0d7;
                        font-size: 14px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .back {
        position: absolute;
        height: 60px;
        width: 60px;
        background: #fff;
        color: #1890ff;
        border: 1px solid #1890ff;
        font-size: 24px;
        border-radius: 50%;
        right: 20px;
        bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            background: #1890ff;
            color: #fff;
        }
    }
}
@media only screen and (max-width: 600px) {
    .the_content {
        width: 100%;
        height: 100%;
        overflow: auto;
    }
}
</style>
