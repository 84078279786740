<template>
  <div class="the_content" v-if="current.spin">
    <div class="top">
      <div class="title">{{ title }}</div>
      <!-- <div class="desc">欢迎使用问卷网！这是一份示例问卷。</div> -->
      <div class="mtop"></div>
    </div>
    <div class="content">
      <div class="title">
        {{ current.step + 1 }}.{{ current.name }}
        <span v-show="current.required" class="asterisk" style="color: red"
          >*</span
        >
      </div>
      <div class="desc">{{ current.desc }}</div>
      <div class="matrix">
        <template v-if="current.type == '单选'">
          <a-radio-group v-model:value="current.answer">
            <a-row>
              <a-col
                :span="24"
                v-for="item in current.options"
                :key="item"
                class="item"
              >
                <a-radio :value="item">{{ item.no }}.{{ item.text }}</a-radio>
              </a-col>
            </a-row>
          </a-radio-group>
        </template>
        <template v-if="current.type == '多选'">
          <a-checkbox-group v-model:value="current.answer">
            <a-row>
              <a-col
                :span="24"
                v-for="item in current.options"
                :key="item"
                class="item"
              >
                <a-checkbox :value="item"
                  >{{ item.no }}.{{ item.text }}</a-checkbox
                >
              </a-col>
            </a-row>
          </a-checkbox-group>
        </template>
        <template v-if="current.type == '填空'">
          <a-textarea
            v-model:value="current.answer"
            placeholder="请输入"
            :rows="4"
          />
        </template>
        <template v-if="current.type == '判断'">
          <a-radio-group v-model:value="current.answer">
            <a-row>
              <a-col :span="24" class="item">
                <a-radio :value="true">是</a-radio>
              </a-col>
              <a-col :span="24" class="item">
                <a-radio :value="false">否</a-radio>
              </a-col>
            </a-row>
          </a-radio-group>
        </template>
        <template v-if="current.type == '分数'">
          <a-input-number
            id="inputNumber"
            v-model:value="current.answer"
            :max="10"
          />
        </template>
        <template
          v-if="current.type == '单选' && current.answer.requireComment"
        >
          <div style="margin-top: 10px">
            <a-textarea
              v-model:value="current.comment"
              placeholder="请输入"
              :rows="4"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="buttom">
      <div class="left">
        <a-button
          style="margin-right: 20px"
          @click="back()"
          v-show="current.step"
          >上一题</a-button
        >
        <a-button
          type="primary"
          @click="next()"
          v-if="current.step != current.done - 1"
          >下一题</a-button
        >
        <a-button type="primary" @click="done()" v-else>提交</a-button>
      </div>
      <div class="right">
        <a-progress :percent="current.percentage" />
      </div>
    </div>
    <div class="back" @click="backPage()">
      <RollbackOutlined />
    </div>
  </div>
  <a-spin v-else />
</template>
<script>
import { onMounted, ref } from "vue";
import { get, post, config } from "@/utils/http";
import { warning } from "@/utils/message.js";
import { Storage } from "wl-core";
import { useRouter } from "vue-router";
import { RollbackOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";

// 计算百分比
const Percentage = (num, total) => {
  if (num == 0 || total == 0) return 0;
  return Math.round((num / total) * 10000) / 100.0;
};
export default {
  components: {
    RollbackOutlined,
  },
  setup() {
    let data = ref({});

    let current = ref({
      step: 0,
      answer: "",
      type: "",
      required: true,
      name: "",
      no: "",
      done: 0,
      options: [],
      percentage: 0,
      comment: "",
      desc: "",
      spin: false,
    });

    let router = useRouter();

    let answerList = ref([]);
    // 初始化题目
    const getCurrent = (data, index) => {
      current.value.step = index;
      current.value.name = data.name;
      current.value.options = data.options;
      current.value.required = data.required;
      current.value.sid = data.sid;
      current.value.type = data.type;
      current.value.no = data.no;
      current.value.percentage = Percentage(
        current.value.step,
        current.value.done - 1
      );
      current.value.comment = "";
      current.value.desc = data.desc;
    };
    // 获取选择值
    const getCurrentAnswer = () => {
      if (current.value.type == "单选") {
        current.value.comment = answerList.value.find(
          (x) => x.question == current.value.sid
        )?.comment;
        current.value.answer =
          answerList.value.find((x) => x.question == current.value.sid)
            ?.values[0] ?? "";
      }
      if (current.value.type == "多选")
        current.value.answer =
          answerList.value.find((x) => x.question == current.value.sid)
            ?.values ?? "";
      if (current.value.type == "判断")
        current.value.answer =
          answerList.value.find((x) => x.question == current.value.sid)
            ?.valueBool ?? "";
      if (current.value.type == "填空")
        current.value.answer =
          answerList.value.find((x) => x.question == current.value.sid)
            ?.valueString ?? "";
      if (current.value.type == "分数")
        current.value.answer =
          answerList.value.find((x) => x.question == current.value.sid)
            ?.valueNumber ?? "";
    };
    // 提交数据
    const save = () => {
      let index = answerList.value.findIndex(
        (x) => x.question == current.value.sid
      );
      if (index > -1) answerList.value.splice(index, 1);
      let answer = {
        question: current.value.sid,
        questionType: current.value.type,
      };
      if (answer.questionType == "单选") answer.values = [current.value.answer];
      if (answer.questionType == "多选") answer.values = current.value.answer;
      if (answer.questionType == "判断")
        answer.valueBool = current.value.answer;
      if (answer.questionType == "填空")
        answer.valueString = current.value.answer;
      if (answer.questionType == "分数")
        answer.valueNumber = current.value.answer;
      answer.comment = current.value.answer.requireComment
        ? current.value.comment
        : undefined;
      answerList.value.push(answer);
    };
    // 上一步
    const back = () => {
      save();
      getCurrent(
        data.value.questions[current.value.step - 1],
        current.value.step - 1
      );
      getCurrentAnswer();
    };
    // 下一步
    const next = () => {
      if (current.value.required)
        if (current.value.answer?.toString() == "")
          return warning("该题目为必填项");
      if (current.value.answer.requireComment)
        if (
          current.value.comment.length <
          current.value.answer.requireCommentLength
        )
          return warning(
            `字数必须大于${current.value.answer.requireCommentLength}`
          );
      save();
      getCurrent(
        data.value.questions[current.value.step + 1],
        current.value.step + 1
      );
      getCurrentAnswer();
    };
    // 提交数据
    const done = async () => {
      if (current.value.required)
        if (current.value.answer?.toString() == "")
          return warning("该题目为必填项");
      if (current.value.answer.requireComment)
        if (
          current.value.comment.length <
          current.value.answer.requireCommentLength
        )
          return warning(
            `字数必须大于${current.value.answer.requireCommentLength}`
          );
      save();
      let ids = Storage.get("ids");
      let res = await post(
        `${config.evalution}/Project/ForH5/${ids.processid}/ProjectEvalutionToMember/${ids.groupid}/${ids.member}/${ids.table}`,
        null,
        answerList.value
      );
      if (res.msg == "success") {
        let detail = Storage.get("detail");
        detail.record.onOperateList.forEach((x, index) => {
          if (ids.table == x.table) {
            x.committed = true;
            detail.record.operateList.push(x);
            detail.record.onOperateList.splice(index, 1);
          }
        });
        Storage.set("detail", detail);
        router.push({ name: "done" });
      } else {
        router.push({ name: "filure" });
      }
    };

    const backPage = () => {
      if (answerList.value.length) {
        Modal.confirm({
          title: "确认返回吗？页面不会保留您的记录",
          centered: true,
          cancelText: "取消",
          okText: "确认",
          onOk() {
            router.push({ name: "selecteachers" });
          },
        });
      } else {
        router.push({ name: "selecteachers" });
      }
    };

    // 获取题目
    onMounted(async () => {
      let ids = Storage.get("ids");
      let res = (
        await get(
          `${config.evalution}/Project/ForH5/${ids.processid}/FlatTable/${ids.table}`
        )
      ).data;
      data.value = res;
      current.value.done = data.value.questions.length;
      getCurrent(data.value.questions[current.value.step], current.value.step);
      setTimeout(() => {
        current.value.spin = true;
      }, 100);
    });

    // 特别题目处理

    return {
      title: Storage.get("data").name,
      data,
      answerList,
      current,
      done,
      next,
      back,
      backPage,
      //radioStyle,
    };
  },
};
</script>

<style lang="less" scoped>
.the_content {
  width: 814px;
  padding: 10px 40px 66px;
  padding-top: 30px;
  background: #fff;
  overflow: auto;
  max-height: 90%;
  .top {
    margin-bottom: 30px;
    .title {
      margin-top: 20px;
      text-align: center;
      font-size: 18px;
    }
    .desc {
      margin-top: 20px;
      font-size: 16px;
    }
    .mtop {
      width: 100%;
      height: 3px;
      background: #1890ff;
      margin-top: 20px;
    }
  }
  .content {
    .desc {
      margin-top: 10px;
      color: #999999;
    }
    .matrix {
      margin-top: 10px;
    }
    .item {
      padding: 5px;
    }
  }
  .buttom {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    align-items: flex-end;
    .left {
      .next {
        margin-left: 20px;
      }
    }
    .right {
      width: 200px;
    }
  }
  .back {
    position: absolute;
    height: 60px;
    width: 60px;
    background: #fff;
    color: #1890ff;
    border: 1px solid #1890ff;
    font-size: 24px;
    border-radius: 50%;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #1890ff;
      color: #fff;
    }
  }
}
@media only screen and (max-width: 600px) {
  .the_content {
    .buttom {
      display: block;
      .right {
        margin-top: 30px;
        width: 100%;
      }
    }
    .back {
      position: fixed;
    }
  }
}
</style>
