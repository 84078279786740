import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/css/app.less'
import router from '@/router'
// vuex 现在用不上
createApp(App)
  .use(Antd)
  .use(router)
  .mount('#app')
