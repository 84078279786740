<template>
    <a-result
        status="success"
        class="the_content"
        title="提交成功"
    >
    </a-result>
</template>  
<style lang="less" scoped>
.the_content {
    width: 814px;
    padding: 30px 0px 66px;
    padding-top: 60px;
    background: #fff;
    position: relative;
}
@media only screen and (max-width: 600px) {
    .the_content {
        width: 100%;
        height: 50%;
        overflow: auto;
    }
}
</style>
