<template>
    <a-result status="error" class="the_content" title="提交失败" :sub-title="`被评价教师:${data.name}`">
        <template #extra>
            <a-button key="console" type="primary" @click="back('detail')">返回</a-button>
            <a-button key="buy" @click="back('selecteachers')">返回列表</a-button>
        </template>
    </a-result>
</template>  
<script>
import { onMounted, ref } from 'vue';
import { Storage } from "wl-core";
import { useRouter } from 'vue-router';
import { get, config } from '@/utils/http';
export default {
    data() {
        return {};
    },
    setup() {
        let data = ref({});
        let router = useRouter();
        data.value = Storage.get("detail");
        let formatDate = (date) => {
            let YY = date.getFullYear() + '-';
            let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            return YY + MM + DD + " " + hh + mm + ss;
        };

        let back = (name) => {
            router.push({ name: name });
        }

        onMounted(async () => {
            setTimeout(async () => {
                let res = (await get(`${config.evalution}/Project/ForH5/GetProjectHandle/5fc9c0a7ab2252d3539dd3f0/${localStorage.getItem('idno')}`)).data;
                Storage.set('data', res);
            }, 200)
        })
        return {
            data,
            back,
            formatDate
        }
    }
};
</script>
<style lang="less" scoped>
.the_content {
    width: 814px;
    padding: 30px 0px 66px;
    padding-top: 60px;
    background: #fff;
    position: relative;
}
@media only screen and (max-width: 600px) {
    .the_content {
        width: 100%;
        height: 50%;
        overflow: auto;
    }
}
</style>
