<template>
    <div class="the_content">
        <div class="title">欢迎使用评价系统</div>
        <a-input-search
            v-model:value="value"
            placeholder="请输入身份证号码"
            enter-button="查询"
            class="input"
            size="large"
            @search="onSearch(value)"
        />
    </div>
</template>
<script>
import { useRouter, useRoute } from 'vue-router';
import { get, config } from '@/utils/http';
import { ref } from 'vue';
import { warning } from '@/utils/message.js';
import { Storage } from "wl-core";
// import * as aaa from 'vue-router'
export default {
    setup() {
        let value = ref('');
        let router = useRouter();
        let route = useRoute();
        let id = route.params.id;
        Storage.set('id', id);
        const onSearch = async (e) => {
            let idnoReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (!idnoReg.test(e)) return warning('请输入有效的身份证号码');
            let res = (await get(`${config.evalution}/Project/ForH5/GetProjectHandle/${localStorage.getItem('id')}/${e}`));
            if (res.msg == "success") {
                Storage.set('data', res.data);
                Storage.set('idno', e);
                router.push({ name: 'selecteachers' });
            }
        }
        // 这里去身份号码的时候发生了错误，所以用浏览器原生事件
        value.value = localStorage.getItem('idno');
        return {
            value,
            onSearch
        }
    }
}
</script>

<style lang="less" scoped>
.the_content {
    width: 814px;
    padding: 10px 40px 66px;
    padding-top: 30px;
    background: #fff;
    .title {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
    }
    .input {
        margin: 20px 10%;
        width: 80%;
    }
}
@media only screen and (max-width: 600px) {
    .the_content {
        padding: 40px 0px 66px;
        width: 100%;
        .input {
            margin: 20px 5%;
            width: 90%;
        }
    }
}
</style>
