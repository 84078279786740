<template>
    <a-result status="error" class="the_content" :title="'该问卷'+msg">
    </a-result>
</template>  
<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
export default {
    data() {
        return {};
    },
    setup() {
        let msg = ref({});
        let route = useRoute();
        msg.value=route.params.msg;
        return {
           msg
        }
    }
};
</script>
<style lang="less" scoped>
.the_content {
    width: 814px;
    padding: 30px 0px 66px;
    padding-top: 60px;
    background: #fff;
    position: relative;
}
@media only screen and (max-width: 600px) {
    .the_content {
        width: 100%;
        height: 50%;
        overflow: auto;
    }
}
</style>
