
let axios = require('axios');
import { Storage } from "wl-core";
import * as message from './message'
axios.interceptors.request.use(
  config => {
    return config
  },
  error => Promise.reject(error)

)
// 自定义判断元素类型JS
function toType(obj) {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 参数过滤函数
function filterNull(o) {
  for (var key in o) {
    if (o[key] === null) {
      delete o[key];
      continue;
    }
    if (toType(o[key]) === 'string') {
      o[key] = o[key].trim();
      if (o[key] === '') {
        delete o[key];
        continue;
      }
    } else if (toType(o[key]) === 'object') {
      o[key] = filterNull(o[key]);
      if (JSON.stringify(o[key]) == '{}') {
        delete o[key];
      }
      continue;
    } else if (toType(o[key]) === 'array') {
      if (o[key].length == 0) {
        continue;
      }
      o[key] = filterNull(o[key]);
      continue;
    }
  }
  return o;
}

/*
  接口处理函数
*/

function apiAxios(method, baseUrl, url, params) {
  if (params) {
    params = filterNull(params)
  }
  return new Promise((resolve) => {
    axios({
      method: method,
      url: url,
      data: method === 'POST' || method === 'PUT' ? params : null,
      params: method === 'GET' || method === 'DELETE' ? params : null,
      baseURL: baseUrl,
      withCredentials: false
    }).then(function (res) {
      if (res.data.statusCode == 200 || res.data.statusCode == 'OK') {
        resolve(res.data)
      } else {
        message.error(res.data.msg);
        resolve(res.data)
      }
    }).catch(function (res) {
      console.log(res)
    })
  })

}
const get = (baseUrl, url, params) => {
  return apiAxios('GET', baseUrl, url, params)
}
const post = (baseUrl, url, params) => {
  return apiAxios('POST', baseUrl, url, params)
}
const put = (baseUrl, url, params) => {
  return apiAxios('PUT', baseUrl, url, params)
}
const del = (baseUrl, url, params) => {
  return apiAxios('DELETE', baseUrl, url, params)
}

let config = Storage.get('config');

let i = setInterval(() => {
  config = Storage.get('config');
  if (config) {
    clearInterval(i);
  }
}, 1000);

export {
  get,
  post,
  put,
  del,
  config,
}