import { createRouter, createWebHistory } from 'vue-router'
import query from '@/pages/query.vue';
import selecteachers from '@/pages/selecteachers.vue';
import detail from '@/pages/detail.vue';
import evaluate from '@/pages/evaluate.vue';
import done from '@/pages/done.vue';
import deailtable from '@/pages/deailtable.vue';
import filure from '@/pages/filure.vue';
import questionnaire from '@/pages2/questionnaire.vue';
import q_done from '@/pages2/q_done.vue';
import q_filure from '@/pages2/q_filure.vue';

const routerHistory = createWebHistory();
let router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/query/:id',
      name: "query",
      component: query
    },
    {
      path: '/selecteachers',
      name: "selecteachers",
      component: selecteachers
    },
    {
      path: '/evaluate',
      name: "evaluate",
      component: evaluate
    },
    {
      path: '/detail',
      name: "detail",
      component: detail
    },
    {
      path: '/done',
      name: "done",
      component: done
    },
    {
      path: '/filure',
      name: "filure",
      component: filure
    },
    {
      path: '/deailtable',
      name: "deailtable",
      component: deailtable
    },
    {
      path: '/questionnaire/:id',
      name: "questionnaire",
      component: questionnaire
    },
    {
      path: '/q_done',
      name: "q_done",
      component: q_done
    },
    {
      path: '/q_filure/:msg',
      name: "q_filure",
      component: q_filure
    },
  ]
})
export default router